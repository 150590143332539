@import '../../../styles/variables.scss';
@import '../../../styles/mixin.scss';


.mobile_intro_wrap {
  width: 100%;
  height: calc(100% + 15px);
}
.mobile_intro_section {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 600px;
  background-size: 400%;
  background-position: 45% 70%;
  background-repeat: no-repeat;
  .mobile_intro_container {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    .mobile_intro_logo_area {
      padding: 20px;
      font-size: 20px;
      .mobile_intro_logo{
        width: 100px;
        height: 17px;
      }
    }
    .mobile_intro_title_area {
      margin-top: 70px;
      padding-left: 20px;
      color: $white;
      .mobile_intro_title {
        font-size: 24px;
        font-weight: 300;
        margin: 7px 0;
      }
      .mobile_intro_title_line {
        width: 40px;
        border-bottom: 2px solid $white;
        margin: 20px 0;
      }
      .mobile_intro_title_bold {
        font-size: 34px;
        font-weight: bold;
        margin: 7px 0;
      }
      :nth-child(5) {
        animation-delay: 0.5s;
      }
      :nth-child(6) {
        animation-delay: 1s;
      }
    }
  }
}

.mobile_section2 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
  background-color: $gray-6;
  .mobile_section2_container {
    width: 100%;
    height: 100%;
    .mobile_section2_area {
      @include flex-direction(column);
      @include justify-content(center);
      @include align-items(center);
      padding-top: 70px;
      .mobile_section2_title_area {
        text-align: center;
        .mobile_section2_title {
          color: $main-color;
          font-size: 28px;
          font-weight: bold;
          padding: 5px;
        }
        .mobile_section2_subtitle {
          color: $main-color;
          font-size: 16px;
          padding: 5px;
        }
        :nth-child(3) {
          margin-top: 30px;
        }
      }
      .section2_card_area {
        margin-top: 54px;
        width: 100%;
        padding: 0 10%;
        .section2_card {
          height: 70px;
          border-radius: 10px;
          box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.16);
          background-color: $white;
          margin: 12px 0;
          min-width: 280px;
          .section2_card_row {
            @include flex_direction(row);
            @include align_items(center);
            @include justify_content(space-between);
            width: 100%;
            height: 100%;
            padding: 0 20px;
            label {
              font-size: 13px;
              color: #707070;
              border-bottom: 3px solid rgba(255, 167, 35, 0.16);
              box-shadow: inset 0 -8px 0 rgba(255, 167, 35, 0.16);
            }
            span {
              color: $black;
              font-size: 24px;
              font-weight: $font-weight-bold;
            }
          }
        }
        .section2_data_month {
          @include center_mid();
          padding: 45px 0;
          font-size: 12px;
          color: #707070;
        }
      }
    }
  }
}

.mobile_section3 {
  width: 100%;
  height: auto;
  .mobile_section3_container {
    width: 100%;
    .mobile_section3_area {
      @include flex-direction(column);
      @include justify-content(center);
      @include align-items(center);
      .mobile_section3_title_area {
        margin: 80px 0 60px 0;
        .mobile_section3_main_title {
          font-size: 28px;
          text-align: center;
          margin: 7px;
        }
      }
      .mobile_section3_image_area {
        width: 310px;
        height: 610px;
        div {
          img {
            display: block;
            margin: 0 auto;
            width: 80%;
            height: auto;
          }
          .mobile_section3_text_area {
            position: absolute;
            width: 310px;
            top: 320px;
            padding: 10px 0 80px 0;
            text-align: center;
            z-index: 10;
            background-color: $white;
            .mobile_section3_text_title {
              font-size: 18px;
              font-weight: bold;
              margin: 20px 0;
            }
            .mobile_section3_text_content {
              margin: 5px 0;
              font-size: 14px;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
}

// secection3 slide
.dots {
  margin: 0;
  width: 20px;
  height: 10px;
  .active_dot {
    width: 20px;
    height: 6px;
    border-radius: 20px;
    background-color: $sub-color;
  }
  .normal_dots {
    width: 6px;
    height: 6px;
    margin: 0 auto;
    border-radius: 20px;
    background-color: #d8d8d8;
  }
}


.mobile_section4 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: calc(100% + 80px);
  background-color: $gray-6;
  .mobile_section4_container {
    width: 100%;
    .mobile_section4_area {
      @include flex-direction(column);
      @include align-items(center);
      padding: 80px 0;
      .mobile_section4_title_area {
        margin-bottom: 60px;
        .mobile_section4_main_title {
          font-size: 28px;
          text-align: center;
          margin: 7px;
        }
      }
      .mobile_section4_image_area {
        width: 85%;
        padding-bottom: 80px;
        max-width: 310px;
        max-height: 550px;
        text-align: center;
        img {
          width: 85%;
        }
        .mobile_section4_text_area {
          position: absolute;
          width: 310px;
          bottom: 0;
          padding-bottom: 80px;
          z-index: 10;
          background-color: $gray-6;
          .mobile_section4_text_title {
            font-size: 18px;
            font-weight: bold;
            margin: 20px 0;
          }
          .mobile_section4_text_content {
            margin: 5px 0;
            font-size: 14px;
            font-weight: 300;
          }
        }
      }
    }
  }
}

.mobile_section5 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: calc(100% + 150px);
  .mobile_section5_container {
    width: 100%;
    .mobile_section5_area {
      @include flex-direction(column);
      @include align-items(center);
      .mobile_section5_title_area {
        margin: 80px 0 60px 0;
        .mobile_section5_main_title {
          font-size: 28px;
          text-align: center;
          margin: 7px;
        }
      }
      .mobile_section5_image_area {
        width: 85%;
        max-width: 310px;
        max-height: 550px;
        text-align: center;
        img {
          width: 85%;
        }
        .mobile_section5_text_area {
          position: absolute;
          width: 310px;
          bottom: -50px;
          padding-bottom: 130px;
          z-index: 10;
          background-color: $white;
          .mobile_section5_text_title {
            font-size: 18px;
            font-weight: bold;
            margin: 20px 0;
          }
          .mobile_section5_text_content {
            margin: 5px 0;
            font-size: 14px;
            font-weight: 300;
          }
          :nth-child(6) {
            margin-top: 30px;
          }
        }
      }
    }
  }
}

.mobile_link_section {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
  min-height: 430px;
  background-position: 50% 60%;
  .mobile_link_container {
    padding: 45px;
    .mobile_content_area {
      color: $white;
      .mobile_link_title {
        font-size: 34px;
        font-weight: bold;
        margin: 5px 0;
      }
      .mobile_link_content {
        font-size: 19px;
        font-weight: normal;
        margin: 5px 0;
      }
      :nth-child(4) {
        margin-top: 40px;
      }
    }
    .mobile_link_img_area {
      @include flex-direction(row);
      @include justify-content(space-between);
      margin-top: 30px;
      div {
        width: 47%;
        height: auto;
        max-width: 150px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.mobile_footer_section {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
  padding-bottom: 60px;
  background-color: $gray-6;
}

.mobile_app_btn_fix_wrap {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 15;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  div {
    @include justify-content(center);
    @include align-items(center);
    height: 60px;
    background-color: $sub-color;
    color: $white;
    border-radius: 0px;
    margin-left: 0;
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;

  }
}

.mobile_app_btn_float_wrap {
  position: fixed;
  width: 100%;
  bottom: 94px;
  z-index: 15;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  div {
    @include justify-content(center);
    @include align-items(center);
    height: 60px;
    background-color: $sub-color;
    color: $white;

    border-radius: 7px;
    margin-left: 7.5%;
    width: 85%;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
}
