@import '../../styles/variables.scss';
@import '../../styles/mixin.scss';

.mobile_footer_container {
  margin: 0 auto;
  width: 100%;
  height: auto;
}

.mobileInnerContainer {
  width: 100%;
  margin: 0 auto;
  height: 100%;
}

.termText {
  color: $white;
  margin-right: 30px;
  font-size: 13px;
}

.mobileFooterImage {
  margin-top: 26px;
  height: 91px;
  width: 80px;
  margin-right: 40px;
  float: left;
}

.mobileFooterInfo {
  margin-top: 46px;
  display: inline-block;
  float: left;
}

.mobile_footer_area {
  padding: 0 20px;
  .mobile_footer_term_area {
    @include flex-direction(row);
    @include justify-content(space-between);
    flex-wrap: wrap;
    margin-top: 50px;
    margin-bottom: 20px;
    .mobile_footer_term {
      font-size: 15px;
      font-weight: bold;
      color: $black;
      width: 50%;
      margin: 10px 0;
      text-decoration: none;
    }
  }
  .mobile_footer_line {
    width: 100%;
    border: 0.5px solid #e6e6e6;
  }
  .mobile_footer_company_info_area {
    margin-top: 30px;
    width: 100%;
    .mobile_footer_company_info_content {
      font-size: 13px;
      margin: 7px 0;
    }
    .mobile_footer_company_link {
      @include flex_direction(row);
      margin: 40px 0 40px 0;
      a {
        margin: 0;
      }
      img {
        margin: 0 20px 0 0;
        cursor: pointer;
        width: 32px;
        height: 32px;
      }
    }
    :first-child {
      margin-bottom: 20px;
    }
    :nth-child(5) {
      margin-bottom: 40px;
    }
  }
}